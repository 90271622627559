<template>
  <v-card tile flat>
    <v-list-item two-line class="clickable">
      <v-list-item-avatar class="text-h5 justify-center"
                          :style="{'background-color': avatarBackgroundColor, 'color': avatarTextColor}">
        {{ firstLetter(client.clientName) }}
      </v-list-item-avatar>
      <v-list-item-content @click="openProjectOverview">
        <v-list-item-title class="body-1 font-weight-medium">{{ client.clientName }}</v-list-item-title>
      </v-list-item-content>
      <v-list-item-action class="ma-0">
        <v-btn
            icon
            :color="client.active ? 'primary':'red'"
            fab
            @click="toggleClientActive"
        >
          <v-icon v-if="client.active">mdi-toggle-switch</v-icon>
          <v-icon v-else>mdi-toggle-switch-off</v-icon>
        </v-btn>
      </v-list-item-action>
    </v-list-item>
  </v-card>
</template>

<script>
import randomColor from 'randomcolor';
import routeNames from "@/router/RouteNames";

export default {
  name: "ClientListComponent",
  props: ['client'],
  methods: {
    openProjectOverview() {
      this.$router.push({
        name: routeNames.TEAM_PROJECT_OVERVIEW,
        params: {
          teamId: this.$router.currentRoute.params.teamId,
          clientId: this.client.id
        }
      })
      this.$store.commit('clientsModule/setClient', this.client)
    },
    toggleClientActive() {
      if (this.client.active && this.client.projects != null) {
        let isOneProjectActive = false
        this.client.projects.forEach(project => {
          if (project.active) {
            isOneProjectActive = true
          }
        })
        if (isOneProjectActive) {
          this.$emit('error', "Klant kan niet op inactief gezet worden, want er is nog een project actief.")
        } else {
          this.$store.dispatch('teamsModule/toggleClientActiveForTeam', {
            clientId: this.client.id,
            teamId: this.$router.currentRoute.params.teamId
          })
          this.$emit('error', null)
        }
      } else {
        this.$store.dispatch('teamsModule/toggleClientActiveForTeam', {
          clientId: this.client.id,
          teamId: this.$router.currentRoute.params.teamId
        })
        this.$emit('error', null)
      }
    },
    firstLetter(clientName) {
      return clientName.charAt(0);
    },
  },
  computed: {
    avatarBackgroundColor() {
      return randomColor({
        seed: this.client.clientName,
        luminosity: 'dark'
      })
    },
    avatarTextColor() {
      return randomColor({
        seed: this.client.clientName,
        luminosity: 'light'
      })
    }
  }
}
</script>