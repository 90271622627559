<template>
  <v-card id="listcard" flat>
    <PromiseContainer :promises.sync="promises">
      <v-card-actions class="pa-0">
        <v-card-title>Overzicht klanten</v-card-title>
        <v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-bind="attrs" v-on="on" small depressed color="primary" class="mr-3"
              @click="syncWithProjectManagement">
              Sync
              <v-icon small>mdi-sync</v-icon>
            </v-btn>
          </template>
          <span>Sync met klantenbeheer</span>
        </v-tooltip>
        <v-text-field type="search" label="Zoeken" append-icon="mdi-magnify" clearable single-line v-model="searchValue"
          class="shrink mr-4"></v-text-field>
        <v-menu bottom left offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn outlined class="ma-4" v-bind="attrs" v-on="on"><span class="hidden-xs-only mr-2">Sorteer</span>
              <v-icon>mdi-sort</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="sortBy = 'Name'">Naam</v-list-item>
            <v-list-item @click="sortBy = 'Number'">Klantnummer</v-list-item>
            <v-list-item>Datum</v-list-item>
          </v-list>
        </v-menu>
      </v-card-actions>
      <v-divider></v-divider>
      <v-list color="background" class="pa-0">
        <ClientListComponent v-for="client in getFilteredClients" :key="client.id" :client="client" />
      </v-list>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="close" color="primary">Terug naar team overzicht</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </PromiseContainer>
  </v-card>
</template>

<script>
import ClientListComponent from "@/components/clientoverview/ClientListComponent";
import routeNames from "@/router/RouteNames";
import PromiseContainer from "@/components/shared/PromiseContainer";

export default {
  name: "TeamClientOverview.vue",
  components: {
    ClientListComponent,
    PromiseContainer
  },
  props: {
    teamId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      sortBy: null,
      searchValue: null,
      promises: [this.$store.dispatch('teamsModule/fetchClientsForTeam', this.teamId)],
    }
  },
  methods: {
    close() {
      this.$router.push({ name: routeNames.TEAM_DETAIL_TEMP, params: { teamId: this.teamId } })
    },
    syncWithProjectManagement() {
      this.promises.push(this.$store.dispatch('teamsModule/syncTeamWithProjectManagement', this.$store.state.teamsModule.team.id))
    }
  },
  computed: {
    getFilteredClients() {
      let clients
      if (this.searchValue === null || this.searchValue === "") {
        clients = this.$store.state.clientsModule.clients
      } else {
        clients = this.$store.state.clientsModule.clients.filter(client => {
          if (client.clientName.includes(this.searchValue) ||
            client.projects.filter(project => project.projectName.includes(this.searchValue)).length > 0) {
            return client
          }
        })
      }
      switch (this.sortBy) {
        case 'Name':
          return clients.sort(function (a, b) {
            let nameA = a.clientName.toUpperCase()
            let nameB = b.clientName.toUpperCase()
            if (nameA < nameB) {
              return -1
            }
            if (nameA > nameB) {
              return 1
            }
            return 0
          })
        case 'Number':
          return clients.sort(function (a, b) {
            return a.id - b.id
          })
        default:
          return clients
      }
    },
  }
}
</script>